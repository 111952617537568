<template>
  <div class="shares-page page">
    <BreadcrumbsComponent title="Акции" />
    <div class="container-padding">
      <div ref="sharesList" class="shares-page__content">
        <div v-if="showShares" class="shares-page__items">
          <ShareComponent v-for="(item, i) in shares.data" :key="i" :data="item" />
        </div>
        <span v-else>Акции отсутствуют</span>
        <PaginationComponent :page="page" :first="first" :list="shares" @change="paginate" />
      </div>
    </div>
  </div>
</template>

<script>
import PaginationComponent from "components/Pagination.vue";
import SHARES_PAGE from "@/graphql/pages/SharesPage.graphql";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ShareComponent from "components/ShareComponent.vue";

export default {
  name: "SharesPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: SHARES_PAGE,
        variables: {
          first: store.state.isMobile ? 8 : 12,
        },
      })
      .then(({ data }) => {
        store.state.shares_page.shares = data.shares_paginate;
        // дефолтные данные
        store.state.global.header_categories = data.header_categories;
        store.state.global.footer_categories = data.footer_categories;
        store.state.global.categories = data.categories;
        store.state.global.brands = data.brands;
        store.state.global.info = data.info || {};
        store.state.global.pages = data.pages;
        store.state.global.branches = data.filials;
      });
  },
  data() {
    return {
      page: 1,
      first: this.$store.state.isMobile ? 6 : 9,
      paginateLoading: false,
    };
  },
  computed: {
    shares() {
      return this.$store.state.shares_page.shares || {};
    },
    showShares() {
      return this.shares && this.shares.data && this.shares.data.length;
    },
    showPagination() {
      return this.showShares && Math.ceil(this.shares.paginatorInfo.total / this.first) > 1;
    },
  },
  methods: {
    async paginate(page) {
      if (!this.paginateLoading) {
        this.page = page;
        this.paginateLoading = true;
        await this.load();
        this.scrollUp();
      }
    },
    async load() {
      await this.$apollo.provider.defaultClient
        .query({
          query: SHARES_PAGE,
          variables: {
            page: this.page,
            first: this.first,
          },
        })
        .then(({ data }) => {
          this.paginateLoading = false;
          this.$store.state.shares_page.shares = data.shares_paginate;
          this.$store.state.categories = data.categories;
        })
        .catch(() => {
          this.filterLoading = false;
          this.paginateLoading = false;
        });
    },
    scrollUp() {
      if (window.scrollY > this.$refs.sharesList.offsetTop) {
        window.scrollTo({ top: this.$refs.sharesList.offsetTop });
      }
    },
  },
  components: {
    ShareComponent,
    BreadcrumbsComponent,
    PaginationComponent,
  },
  metaInfo: {
    title: "Акции",
  },
};
</script>

<style lang="stylus">
.shares-page {
  width 100%
  display grid
  grid-gap 32px
  padding-top 32px
  padding-bottom 48px
  +below(420px) {
    grid-gap 16px
  }

  &__content {
    display grid
    grid-gap 32px
    width 100%
    max-width var(--main_width)
  }

  &__items {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px
    +below(990px) {
      grid-template-columns 1fr 1fr
    }
    +below(640px) {
      grid-gap 15px
    }
    +below(540px) {
      grid-template-columns 1fr
    }
  }

  &__container {
    display flex
    justify-content center
  }
}
</style>
