<template>
  <ul v-show="total && total > 1" class="pagination">
    <li v-show="page !== 1">
      <button class="pagination__link" type="button" @click="$emit('change', 1)">
        <IconComponent name="arrow-left-3" />
      </button>
    </li>
    <li v-for="p in currentPages" :key="p">
      <button
        :class="{ 'pagination__link--active': page === p }"
        class="pagination__link"
        type="button"
        @click="$emit('change', p)"
      >
        {{ p }}
      </button>
    </li>
    <li>
      <button v-show="page !== total" class="pagination__link" type="button" @click="$emit('change', total)">
        <IconComponent name="arrow-right-3" />
      </button>
    </li>
  </ul>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "PaginationComponent",
  props: {
    page: Number,
    first: Number,
    list: Object,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = this.$store.state.isMobile;
  },
  computed: {
    total() {
      if (this.list && this.list.paginatorInfo) {
        return Math.ceil(this.list.paginatorInfo.total / this.first);
      }
      return 0;
    },
    currentPages() {
      let startPage, endPage;
      const maxPages = 4;
      if (this.total <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = this.total;
      } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (this.page <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (this.page + maxPagesAfterCurrentPage >= this.total) {
          // current page near the end
          startPage = this.total - maxPages + 1;
          endPage = this.total;
        } else {
          // current page somewhere in the middle
          startPage = this.page - maxPagesBeforeCurrentPage;
          endPage = this.page + maxPagesAfterCurrentPage;
        }
      }
      return Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.pagination {
  display flex
  align-items center
  list-style none
  overflow: hidden;
  gap 5px

  li {
    width 50px
    height 50px
  }

  &__link {
    display flex
    align-items center
    justify-content: center;
    font-weight normal
    width 100%
    height 100%
    background none
    cursor pointer
    border-radius var(--main-radius)
    border 2px solid var(--border-color)
    transition var(--transition)
    font-size 1em
    line-height 1

    &--disabled {
      cursor: initial;
    }

    .icon {
      width 20px
      height 20px
    }

    &:not(&--disabled):not(&--active):hover {
      border-color var(--brown)

      & ^[0]__icon svg path {
        stroke var(--gray)
      }
    }

    &--active {
      cursor initial
      background var(--blue)
      color var(--white)
      border-color var(--blue)
      pointer-events none
    }
  }
}
</style>
